import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/MyNft.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
import { Link, useSearchParams } from "react-router-dom";

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";

const ethereumAddressValidator = require("web3-utils").isAddress;

// MEGA CONFIGS!!!!
// ##################################################################################################

    // POP IN THE MERKLE API URI:   NOTE, I HAVE MULTIPLE ACTIVE ONES NOW, SO BE CAREFUL!
    let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api.netlify.app/.netlify/functions/express/api/"; 

    // MAIN NORMAL STUFF TO DEFINE:
    let CONFIGS_DROPNAME = 'Glitch Deck NFT';  // used in ALTs and so forth
    let CONFIGS_MINT_PRICE_CALC = 0.004; // used for calculating
    let CONFIGS_PROMO_PRICE_CALC = 0.004; // used for calculating
    let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;
    const CONFIGS_TOTALSUPPLY_CALC = 8100; // used for calculating -- I think we may need this to calculate SOLD OUT
    let CONFIGS_TOTALSUPPLY_DISPLAY = '8,100'; // used for display purposes only...

    //todo: go get this value automatically...
    let CONFIGS_NFTS_RESERVED = 137; // I think we may need this to calculate SOLD OUT
    let reserve_supply;    

    let CONFIGS_WALLET_LIMIT = 100; // High limit public, 10 presale..
    let CONFIGS_TRANSACTION_LIMIT = 25; // testing only! Actual settings tbd.

    const CONFIGS_CONTRACT_ADDRESS = "0x4149bc40D03aFa3CE559FD2BFA321d437390D675";
    let CONFIGS_CHAIN_ID = 1; // Chain ID:  1==MAINNET, 5==GOERLI
    let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
    let CONFIGS_NETWORK = ''; //SB: blank (mainnet) or 'goerli.' (w/ period) -- used for link building only
    const CONFIGS_JSON_METADATA_BASEURI = 'ipfs://QmVPLq3wPzs1K429DLJy8iJuSdRWoVDiTb4TA8JFi7fNGb/';
    let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
    let CONFIGS_NFT_TITLE_SINGULAR = 'GLITCH DECK PIECE';
    let CONFIGS_NFT_TITLE_PLURAL = 'GLITCH DECK PIECES';
    let CONFIGS_URLS_TWITTER = 'https://twitter.com/SwiggaJuice';
    let CONFIGS_URLS_MEDIUM = 'https://medium.com/web-design-web-developer-magazine';
    let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/glitchdeck';
    let CONFIGS_URLS_PROJECTWEBSITE = 'https://glitchdeck.xyz/';
    let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;
    let CONFIGS_SHOW_COLLECTION = 0; // make 1 to show, 0 to NOT show NFT collection at bottom.

    // VARIOUS MESSAGES / STRINGS:
    let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting app and your wallet is not on the list. Please mint via the public mint, and/or chat with Jim (@SwiggaJuice) if you believe this to be in error. The snapshot for this allow list was taken on Feb. 11, 2023.';

    let CONFIGS_BEGIN_MINT_ALERT = 'Minting now... please click Okay and hang out for a minute while we write your mint to the Ethereum blockchain. Another alert will popup shortly and let you know that the mint is complete. Queue elevator music...';

    let CONFIGS_MINT_SUCCESS_ALERT = 'Awesome!!! You have succesfully minted! Your Glitch Deck NFT(s) will appear in your wallet and on OpenSea shortly. (You may need to refresh metadata while there.) Super-important: Sharing your animated Glitch Deck NFT on socials will massively help! But these are animated GIFs, so see our instructions "Sharing After Minting" on the web page below so you can easily flex your animated NFTs on Twitter and elsewhere. Thanks!';

    // VARIOUS ERRORS FROM SOLIDITY:
    // Fill this object with some terms that would be found from our solidity contract's errors,
    // and then we can write custom responses for the alerts (but keep them all up here):
    let SOLIDITY_ERROR_LIST = {
        1: {
            'error': '[error text snippet from wallet error]',
            'response': '[alert response]'
        },
        2: {
            'error': 'exceed the wallet limit',
            'response': 'Your transaction would exceed the wallet limit.'
        },
        3: {
            'error': 'exceed the max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        4: {
            'error': 'exceed max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        5: {
            'error': 'Sale is not active',
            'response': 'Sorry, public minting is disabled currently, as the sale has been disabled on the smart contract. Public mint starts on February 21. There is an allowlist-only mint currently for Deck of Degeneracy (DoD) holders. See the DoD Discord for the URL.'
        },
        6: {
            'error': 'Not enough ether sent',
            'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
        },
        7: {
            'error': 'User denied transaction',
            'response': 'The user has denied the current transaction.'
        },
        8: {
            'error': 'already claimed',
            'response': 'It looks like you have already claimed your allowlist NFTs.'
        },
        9: {
            'error': 'insufficient funds',
            'response': 'Insufficient funds. Please add enough ETH to your wallet for this NFT purchase + gas.'
        },
        10: {
            'error': 'are not allowlisted',
            'response': 'Sorry, you are not on the allow list. Please contact Jim (@SwiggaJuice) if you believe this to be an error.'
        }, 
        11: {
            'error': 'exceed the transaction',
            'response': 'Sorry, you cannot purchase that many NFTs in a single transaction during this mint.'
        },
        12: {
            'error': 'exceed the allow list limit',
            'response': 'It looks like you have already claimed some or all of your allow list mints. Any more mints and you would exceed the limit. If you have claimed them all, then you are done. If you have claimed some, then try reducing the amount requested. Kthx. -Jim'
        }        
    }

    let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <Web3ReactProvider getLibrary = { getLibrary }><App/></Web3ReactProvider>) };

const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        console.log('running')
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
    const [reserveSupply, setReserveSupply] = useState(CONFIGS_NFTS_RESERVED); 
    
    let tokenId;
    let signer;
    let currentTotalSupply;

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log("Mint amount = " + value);
    };

    const setupLibrary = async() => {

        try {

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup Library");

        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {

        try {
            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(CONFIGS_TOTALSUPPLY_CALC);
                setIsSold(true);
            }
            console.log('mintCount: ' + mintCount);

            console.log('EventListener...'+searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('EventListener Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('EventListener Affiliate activated? : ' + isActive);    

                if (theCommission && isActive) {
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                }

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const askContractToMintNft = async() => {
        
        console.log('ChainID is: '+chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            let isAffiliate = false;
            console.log(searchParams);
            console.log(searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

            // Cast to lowercase because we don't want case to be a problem...
            // Affiliates can share refs like Jim, jim, JIM, etc. and it'll still
            // work because we are enforcing all lowercase on the contract side.
            affiliateRef = affiliateRef.toLowerCase();
            console.log('Affiliate ref is:'+affiliateRef);

            if(affiliateRef){

                // ok looks like an affiliate sale.
                isAffiliate = true;
              
                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('Affiliate activated? : ' + isActive);

                // If eitehr of those tests fail, we will not pass this along to the mint function
                // as an affiliate sale because it would throw a needless error. Instead, we will
                // pass it as just a non-commission sale. Kthx.
                if (theCommission==0 || isActive==false) { 
                    affiliateRef=''; isAffiliate = false; 
                    console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because no fee structure was estabished on the smart contract and/or the affiliate was not activated on the contract.');
                }

            }

            console.log("Public Minting: Popping wallet open now.")
            
            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.mint(value, isAffiliate, affiliateRef, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });

            console.log("Public Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Mint tx: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const allowlistMint = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            const walletAdd = await signer.getAddress();
            console.log(String(walletAdd));
            const requestOptions = {
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }

            let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
            console.log(res);

            let merkleObject = await res.json();
            console.log(merkleObject);

            if (!merkleObject.valid) {
                alert(CONFIGS_ALLOW_LIST_MESSAGE);
                return;
            }

            console.log("Allowlist Mint: Popping wallet now to pay gas.");

            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Allowlist Ttl Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });
            
            console.log("Allowlist Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {

            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getTotalNFTsMintedSoFar = async() => {

        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("Getting Mint Count")
            let mint_count = await connectedContract.totalSupply();
            console.log(ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));
            console.log(`Set mint count is ${mint_count}`);

            console.log("Getting Reserved Supply")
            let reserve_supply = await connectedContract.reservedNFTs();
            setReserveSupply(ethers.utils.formatUnits(reserve_supply, 0));
            console.log(`Reserve supply is ${reserve_supply}`);


            console.log('Getting Minted NFTs');
            const userBalance = await connectedContract.balanceOf(account);
            console.log(userBalance);

            if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setIsSold(true);
            }

        } catch (error) {

            console.log(error)

        }
    }

    const getTotalOwnerNFTs = async() => {

        if (CONFIGS_SHOW_COLLECTION == 1) {

            try {
                console.log("Getting total owner nfts");
                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                const userBalance = await connectedContract.balanceOf(account);
                console.log(userBalance);
                let arrNFT = []
                let data = null;

                for (let i = 0; i < userBalance; i++) {
                    console.log("entering collection for time: ", i);
                    tokenId = await connectedContract.tokenOfOwnerByIndex(account, i);
                    var openSeaLink = "https://" + CONFIGS_OPENSEA_URL + "/assets/" + CONFIGS_CONTRACT_ADDRESS + "/" + tokenId;

                    let metadataLink = CONFIGS_JSON_METADATA_BASEURI + tokenId;

                    await fetch(metadataLink)
                        .then(
                            function(response) {

                                if (response.status !== 200) {
                                    console.log('Error code: ' + response.status);
                                    return;
                                }

                                response.json().then(function(data) {

                                    //console.log(data);

                                    //console.log('CurrentID: ' + tokenId);
                                    var jsonRes = JSON.parse(JSON.stringify(data));
                                    var imageSrc = jsonRes.image;
                                    imageSrc = imageSrc.replace("ipfs://", "https://ipfs.io/ipfs/");
                                    data.image = imageSrc;
                                    data.openSeaLink = openSeaLink;
                                    //console.log('img src=' + imageSrc);
                                    arrNFT.push(data);
                                    setMyNFTs([...arrNFT]);
                                    let collectionImage = '<div class="collectionItem"><a target="blank" href="' + openSeaLink + '"><img class="collectionImage" src="' + imageSrc + '" /><div class="collectionTokenTitle">' + CONFIGS_NFT_TITLE_SINGULAR + ' #' + tokenId + '</div></a></div>';

                                });
                            }
                        )
                        .catch(function(err) {
                            console.log('Fetch Error :-S', err);
                        });

                }

                console.log(myNFTs);
                console.log(arrNFT);

            } catch (error) {
                console.log(error)
            }

        }

    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log('disconnect acct: '+account);
        console.log('disconnect lib: '+library);
        console.log('disconnect conn: '+connector);
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);
        console.log('disconnect mint price: '+CONFIGS_CURRENT_PRICE);
        
    }

    useEffect(() => {
        setupLibrary();
    });

    useEffect(() => {
        if (library) { setupEventListener() };
    }, [isLibrary]);

    useEffect(() => {
        console.log(isLibrary);
        console.log(trackAnyMintCount);
        if (library) { getTotalNFTsMintedSoFar() }
    }, [isLibrary, trackAnyMintCount]);

    useEffect(() => {
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);

    return (

        <div className="App">

            <div className="header" >

                <div className="headerLeft">

                    {/*<a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="gdlogo.jpg" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a>  */}

                     <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="gdlogo.jpg" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a>

                </div>

                <div className="headerRight">

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="gdlogo.jpg" alt={CONFIGS_DROPNAME + ' Home'} title={ CONFIGS_DROPNAME + ' Home'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_MEDIUM }><img src="logo-medium.png" alt={'Read ' + CONFIGS_DROPNAME + ' news on Medium'} title={'Read ' + CONFIGS_DROPNAME + ' news on Medium'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }><img src="logo-opensea.png" alt={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} title={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} /></a> 
                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }><img src="logo-etherscan.png" alt={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} title={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} /></a> 
                </div>

            </div>

            <div className="clear"> </div>

            <div className="mintBoxWrapper" >

                <div className="mintBoxLeft" >

                    <div className="allButMobile">
                        <img src="featured.gif" alt={CONFIGS_DROPNAME} />

                        <div className="leftArea1 coloralt">
                            <div className="glitch-wrapper"><div className="glitch8" data-glitch="Share After Minting">Share After Minting</div></div>
                            <p>To share animated GIFs:</p>
                            <ul>
                                <li>Open your NFT on OpenSea</li>
                                <li>Right-click/save to your device</li>
                                <li>When tweeting, click the add media button</li>
                                <li>Select the file & share</li>
                                <li><span className="shareMessage">Tag shares with #glitchdeck</span></li>
                            </ul>
                        </div>

                        <div className="leftArea1">
                            <div className="glitch-wrapper"><div className="glitch2" data-glitch="Features">TL;DR</div></div>
                            <p>There is nothing in the generative NFT space quite like Glitch Deck! This is your chance to collect something that stands apart from every other collection.</p>
                            <ul>
                                <li>8,100 unique, collectible, generative NFTs</li>
                                <li>Mint at 0.004 -- less than $10 USD!</li>
                                <li>All are fully animated GIFs</li>
                                <li>Aces through Kings of all suits, including Jokers</li>
                                <li>14 primary card images with many possible animations</li>
                                <li>Carefully curated and modified AI imagery as well as a coded algorithm for the card face backgrounds</li>
                                <li>40+ image background possibilities</li>
                                <li>Hundreds of playing-card-background color-scheme possibilities</li>
                                <li>500+ customized glitched image</li>
                                <li>Glitch effect possible on nearly every aspect</li>
                                <li>Dynamic traits that can vary in number or appearance on each NFT</li>
                                <li>Possible bonus traits like rockets, Matrix-style cascades, bombs / explosions, falling coins, moving clocks, and more</li>
                                <li>A library of hundreds of possible Shakespeare quotes (relevant to each numbered card) that float / overlay many cards (except Aces)</li>
                                <li>A "Prize Box" for future fun prize-based utility (not promised, but there is a cool system in place for this)</li>
                                <li>Bonus easter-egg type features and effects making each NFT a unique experience</li>
                            </ul>
                        </div>

                        <div className="leftArea1 coloralt">
                            <div className="glitch-wrapper"><div className="glitch3" data-glitch="New to NFTs?">New to NFTs?</div></div>
                            <p>If you're new to NFTs and want to mint, I've  written articles to ease your way into the NFT world. These "friend links" will get you behind the Medium.com paywall where you can read how to set things up.</p>
                                <ul>
                                    <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-setup-a-metamask-wallet-for-buying-ethereum-nfts-e85d4ba29523?source=friends_link&sk=62b1489adf954156edfbbc6b9bb49c65" target="_blank">NFT Basics: How to Setup a MetaMask Wallet for Buying Ethereum NFTs</a></li>
                                    <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-mint-your-first-nft-from-an-nft-drops-mint-page-42b085053ff8?source=friends_link&sk=65744255707e1fef230555913872cdb2" target="_blank">NFT Basics: How to Mint Your First NFT from an NFT Drop's Mint Page</a></li>
                                    <li><a href="https://medium.com/web-design-web-developer-magazine/nft-security-basics-part-1-of-2-general-cyber-security-278e9c612313?source=friends_link&sk=71e875623aeec473c636dddf194c8a73" target="_blank">NFT Security Basics, Part 1 of 2: General Cyber Security</a></li>
                                    <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-the-top-800-things-anyone-can-easily-do-to-stay-safe-in-the-crypto-nft-space-717d743ed1a8?source=friends_link&sk=d73c1860bbe441e99b55e6b14bbaee8f" target="_blank">NFT Security Basics, Part 2 of 2: Crypto Wallet Safety</a></li>
                                </ul>
                            <p>Be safe out there and reach out to anytime for minting assistance. You can connect with me at:</p>
                            <ul>
                                <li><a href="https://medium.com/web-design-web-developer-magazine" title="_blank">Medium</a> (my NFT blog)</li>
                                <li><a href="https://twitter.com/SwiggaJuice" title="_blank">Twitter</a></li>
                                <li><a href="https://www.linkedin.com/in/jimdee/" title="_blank">LinkedIn</a></li>
                                <li><a href="https://github.com/jpdnft" title="_blank">GitHub</a></li>
                                <li><a href="https://www.facebook.com/rawfood" title="_blank">Facebook</a></li>
                                <li><a href="https://generativenfts.io/" title="_blank">GenerativeNFTs.io</a></li>
                                <li>Also SwiggaJuice#1447 on Discord, and SwiggaJuice on Instagram.</li>
                            </ul>
                            <p className="allcentered"> ♠ ♣ ♥ ♦ <a href="https://etherscan.io/address/0x4149bc40D03aFa3CE559FD2BFA321d437390D675#code" target="_blank">Glitch Deck's Verified Contract</a> ♠ ♣ ♥ ♦ </p>
                        </div>

                        <img src="gennfts.webp" alt={CONFIGS_DROPNAME} />

                        <div className="leftArea1 allcentered">

                            <div className="glitch-wrapper"><div className="glitch3" data-glitch="Listed At:">Listed At:</div></div>
                        
                            <p>Rarity Sniper <a href="https://raritysniper.com/nft-drops-calendar">NFT Drops</a></p>

                            <p><a href="https://icy.tools/calendar" target="_blank"> <img src="https://cdn.icy.tools/images/icy-tools-light.svg" alt="as seen on icy.tools" width="256" /></a></p>

                        </div>

                    </div>

                </div>

                <div className= "mintBoxRight" >

                    <div className="mintBoxRightWelcome">

                        <img className= "mobileOnly" src="featured.gif" alt="" />

                        <div className="glitch-wrapper"><div className="glitch" data-glitch="What is Glitch Deck?">What is Glitch Deck?</div></div>
                        <p><strong>Glitch Deck NFTs</strong> is a pioneering glitch-art NFT project spanning <span className="blue">8,100 unique, generated, animated playing cards</span> -- each one a unique viewing <em>experience</em> -- stored as Ethereum NFTs. The animations feature many dynamic traits (not simply moving traits, but traits that appear / move differently each time they appear), all themed around <span className="blue">gaming / gambling, pop-culture, and tech</span>. The set incorporates heavily modified AI imagery as well as hand-drawn, custom-designed, custom-coded traits. The combined final files for this project totaled 80GB(!), which ranks Glitch Deck among the largest, most complex generative NFT sets ever. Don't miss your chance to hold one or more of these glitch-art collectibles. <span className="blue">Mint now by connecting your Ethereum wallet below.</span></p>

                        <p></p>

                    </div>

                    <div id="DAppArea" >

                        {
                            (active === false) ? ( 

                                <div id="prepare">

                                    {/* <p><br/><b> Note: </b> If you do not have a wallet installed, you will not see a "Connect Wallet" button.<br/></p> 
                                    ♠ ♣ ♥ ♦
                                    */}

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >♦  NOW MINTING!  ♠♣  CLICK TO CONNECT & MINT  ♥</button> 

                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                    </Modal>

                                </div>

                            ):(

                                (isSold === false) ? ( 

                                    <div className="mintInteractionArea">
                                        
                                        
                                        <p className="sub-text" > Supply:{ ' ' } <span className="yellow">8,100</span> (incl. <span className="yellow">{ reserveSupply }</span> reserves)</p> 
                                        
                                        <p className="sub-text" > Minted:{ ' ' }<span id="currentMintCount" className="yellow">{ mintCount }</span> { ' ' } / { ' ' } 8,100</p>
                                        
                                        <p className="sub-text"> Mint Price @ <span className="yellow">{ mintPrice }</span> ETH</p>

                                        <p className="sub-text" style = { { marginBottom: "6px" } }>Select Mint Quantity:</p>

                                        <div className="theSlider">
                                            <input id="sliderVal"  className="slider"  onChange = { handleChange }  max={ CONFIGS_TRANSACTION_LIMIT } min="1" type="range"  value = { value } /> 
                                        </div>                                        

                                        { /* PRESALE MINT BUTTON  
                                        <button onClick = { allowlistMint } className="btn-custom btn-primary pageButton" type="button" id="theMintButton" >Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                        } </button> */ }
                                        

                                        { /* PUBLIC SALE MINT BUTTON -- */ }
                                        <button onClick={ askContractToMintNft } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">Mint { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL }
                                        </button> 
                                        

                                        <div id="totalPrice" >@ <b>{ (CONFIGS_CURRENT_PRICE * value).toFixed(4) }</b> ETH + gas </div>

                                        <div id="connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span class='smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After minting, head to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.<br></br>Be sure to right-click, save, and then share on Twitter using <span className="shareMessage">#glitchdeck</span>.<br></br>If you want to <a href="https://etherscan.io/address/0x4149bc40D03aFa3CE559FD2BFA321d437390D675#writeContract" target="_blank">mint directly from the verified contract</a>,<br></br>look for the contractMint() function. Kthx.</p>

                                        </div> 


                                    </div>

                                ) : (

                                    <div id="totalPrice">COLLECTION IS SOLD OUT<br /><a href={CONFIGS_URLS_OPENSEA_COLLECTION}>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className="soldFont" id="amountSold"> </span></p>

                    </div>

                    <div className="moreInfo">

                        <div className="glitch-wrapper"><div className="glitch11" data-glitch="Artist's Statement">Artist's Statement</div></div>
                        <p>When a friend commented on the allure of glitch art to me recently, I replied that I suspect that the primary driver of such possibilities lies in the glitching nature itself -- but not merely the literal "glitch," which at its most fundamental is simply a representation of a momentary malfunction (which itself is highly interesting to me), but in the psychology lurking behind that. The artful application of this effect can induce experiences via the barely-perceptible (or even via the downright subliminal), ushering viewers toward rare doorways into the psyche (perhaps the artist's, perhaps their own) -- thresholds into the subconscious realm, through which may lie an assortment of wonder, fantasy, terror, whimsy, etc. Throughout these 8,100 <em>experiences,</em> <span className="blue">Glitch Deck imagines a fantasy world dominated by blocks, cubes, and linear imagery, rich with movement and replete with weird easter eggs, unexpected juxtapositions, interesting deviations, and many surprises.</span> It's my third large-ish set (following up my minted-out LoopieLoo generative set from 2022 and my genesis NFTuxedoCats from early 2021) and my first foray into animation and glitch art. I've learned a ton in the process and will be producing a good bit of 1/1 and limited-edition glitch-style pieces in the months ahead. Thanks for your support!</p>

                        <div className="glitch-wrapper"><div className="glitch4" data-glitch="Building Awareness">Building Awareness</div></div>
                        <img className="rtjack" src="dodjack.webp" />
                        <p>This project has an interesting marketing approach -- adding massive value to an existing collection called the <b>"Deck of Degeneracy" (DoD)</b>, one of my favorite drops that I minted and hold. As a full-time web3 coder since 2021, I've watched artists and small teams struggle with finding audiences. Marketing is one of the toughest aspects of the space -- arguably more challenging than the tech stack!</p>

                        <p>And so I approached the DoD with an idea: What if I could provide substantial value to the entire community? Over time, the idea solidified into all of the detail found on my Medium blog -- see <a href="https://tinyurl.com/glitchNFTs" target="_blank">tinyurl.com/glitchNFTs</a>. The DoD has (as of my snapshot) 512 holders who love poker, gaming, and digital art. So, for this drop, all DoD 512 holders will receive:</p>
                            <ul>
                                <li>FIVE free mints per holder. (If you were a DoD holder as of my snapshot, you are automatically allowlisted and can claim your NFTs <a href="https://dod.glitchdeck.xyz/"><span className="blue">here</span></a>).</li>
                                <li>The five free mints are guaranteed through Feb. 21, 2023. After that, the allowlist claim site will stay open, but the public mint will launch as well. Thus, the public could snatch up the balance anytime. So, DoD members, please mint soon!</li>
                                <li>The <a href="https://medium.com/web-design-web-developer-magazine/imagine-sending-0-129-eth-200-usd-directly-to-500-holders-of-an-nft-community-details-here-2ccdb6c4a2a" target="_blank">Medium artice linked to above</a> outlines my ambitious plan to (potentially) award ~$100k to the DoD community. For this to happen, we will need to sell out at 0.042 ETH. Basically, I am dedicating 40% of primary sales to the DoD community, per the guidelines in that article. If it mints out, each DoD holder as of my snapshot date will be sent their share (~.128 ETH). What a potential coup for an NFT community!</li>
                                <li>There were 96 individual wallets that own more than 5 DoDs. You can see in the <a href="https://etherscan.io/address/0x4149bc40D03aFa3CE559FD2BFA321d437390D675#code" target="_blank">verified smart contract</a> that I've reserved 96 NFTs for the same wallet that will hold the 40% ETH funds for DoD. These 96 will be claimed by me upon mint-out, and will be airdropped to those 96 holders. Each of these will contain a poker-specific trait that I'm holding back just for this group.</li>
                                <li>There are also 43 reserved NFTs for the top DoD whales.</li>
                            </ul>
                        
                        
                        <div className="glitch-wrapper"><div className="glitch5" data-glitch="Selected CV for GenerativeNFTs.io">Selected CV for GenerativeNFTs.io</div></div>
                        <p><a href="https://twitter.com/SwiggaJuice" target="_blank">Jim Dee</a> and <a href="https://twitter.com/WendiDeeNFT" target="_blank">Wendi Dee</a> run GenerativeNFTs.io, an NFT agency specializing in large-scale 10k generative drops. Since 2021 we've served on dozens of drop teams, including these ones and others many would recognize:</p>

                        <ol>
                            <li><a href="https://opensea.io/collection/superfuzz-the-bad-batch" target="_blank">SuperFuzz Bad Guys</a> (sold out -- we did the generative art coding and participated in team strategy.)</li>
                            <li><a href="https://opensea.io/collection/thepppandas" target="_blank">PPPandas</a> (sold out -- we did the generative art coding.)</li>
                            <li><a href="https://opensea.io/collection/superfuzz-the-good-guys" target="_blank">SuperFuzz Good Guys</a> (sold out -- we did the generative art coding and participated on the team.)</li>
                            <li><a href="https://opensea.io/collection/chefboirdogemutts" target="_blank">Chef Dogs Gen1</a> (now minting -- we did the gen art, smart contract, minting app.)</li>
                            <li><a href="https://opensea.io/collection/sandhelm" target="_blank">Sandhelm</a> (now minting -- we did the gen art, smart contract, minting app, and more.)</li>
                            <li><a href="https://opensea.io/collection/prayinghandsclub" target="_blank">Praying Hands Club</a> (sold out -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
                            <li><a href="https://opensea.io/collection/imakittycat" target="_blank">I'm a Kitty Cat</a> (now minting -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
                            <li><a href="https://thetadrop.com/marketplace/edition/type_tpqv6d16v5fp0gudtt0vyf6qsqm" target="_blank">Order of the Tigons</a> (sold out -- the Napoleoon Dynamite drop on Theta chain -- we did the art coding and participated on the team.)</li>
                            <li><a href="https://opensea.io/collection/yearofthewoman" target="_blank">Year of the Woman</a> (sold out -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
                            <li><a href="https://opensea.io/collection/loopieloo" target="_blank">Loopies</a> (minted out -- my own free mint project -- did everything.)</li>
                            <li><a href="https://opensea.io/collection/astralpioneers" target="_blank">Astral Pioneers</a> (now minting -- did everything, plus an amazing guy on our team did the art.)</li>
                            <li><a href="https://opensea.io/collection/cbrdmixedbreeds" target="_blank">Chef Dogs Gen2</a> (now minting -- gen art, smart contract, minting app.)</li>
                            <li><a href="https://nftgreetings.xyz" target="_blank">NFTGreetings.xyz</a> (just launched -- internal new project with 11 mainnet contracts/minting apps launched so far; with collabs slated.)</li>
                            <li><a href="https://frowny.town" target="_blank">FrownyTown</a> (coming spring 23 -- gen art, smart contract, minting app, advisory services.)</li>
                            <li><a href="https://lapinc.io" target="_blank">LAPiNC</a> (coming spring 23 -- gen art, smart contract, minting app, advisory services.)</li>
                            <li><a href="https://spiritualbeings.io" target="_blank">Spiritual Beings</a>: (coming spring 23 -- gen art, smart contract, minting app, team participation. Top quality team lead by former chief of staff of Boss Beauties.)</li>
                            <li><a href="https://mightymetas.com" target="_blank">Mighty Metas</a>  (coming spring 23 -- gen art, smart contract, minting app, advisory services.)</li>
                            <li><a href="https://firstapewivesclub.io" target="_blank">First Ape Wives Club</a> (coming spring 23 -- gen art, smart contract, minting app, team members. -- well marketed project, likely to be a sellout via numerous celeb ambassadors.)</li>

                        </ol>

                        <img className="bottomgif" src="79.gif" alt={CONFIGS_DROPNAME} />

                        <hr></hr>
                        <a className="textLinks" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }>Jim's Twitter</a> 
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a className="textLinks" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_MEDIUM }>Jim's Medium</a> 
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a className="textLinks" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }>Glitch Deck on OpenSea</a> 
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a className="textLinks" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }>Glitch Deck Verified Contract</a> 


                    </div>

                </div>

            </div>

            <div className="clear" > </div>

            {

                (CONFIGS_SHOW_COLLECTION == 1) ? (

                    <div>

                        <div className="collectionWrapper" >

                            <h2>Your NFTs From This Collection</h2>

                            <p style = { { maxWidth: "80%", marginLeft: "auto", marginRight: "auto", fontSize: "125%", padding: "20px" } } > After purchasing NFTs from this collection, your NFTs will be written to the blockchain and will then appear below.They will also link to OpenSea, where you will be able to view NFT properties(once revealed), view the collection, and buy / sell / trade NFTs. </p>

                            <div className="collectionRow" id="renderCollection">

                                {
                                    myNFTs.map((myNFT) =>
                                        <div key={ myNFT.name } className="collectionItem">
                                            <a target="_blank" rel="noreferrer" href={ myNFT.openSeaLink }>
                                                <img className="collectionImage"  src={ myNFT.image }  alt="" />
                                                <div className="collectionTokenTitle">
                                                    <p>{ myNFT.name }</p>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }

                            </div>

                        </div>

                        <div className="clear" ></div> 

                    </div>

                ) : ( 
                
                    <div className="noCollectionSpacer"> </div>

                )

            }

        </div>

    );

};
